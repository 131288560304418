import React, { useEffect } from "react";
import { Button, TextField, MenuItem } from "@mui/material";
import { UserLogin } from "../../../context/AuthContext";
import ReactCountryFlag from "react-country-flag";
import { styled } from "@mui/material/styles";
import startsWith from "lodash.startswith";
import "../../../App.css";
import { USER_PROFILE_URL } from "../../../Auth_API";
import Progress from "../../Loader/Progress";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";

export default function PersonalInfo() {
  const { t } = useTranslation();
  const {
    userData,
    cvCredentials: {
      first_name,
      last_name,
      phone_number,
      date_of_birth,
      nationality,
      city,
      state,
      zip,
      current_address,
      cv_gender,
    },
    cvCredentials,
    setCVCredentials,
    onChangeCVCredentials,
    setShowPersonalInfoForm,
    setShowEducationForm,
    setActiveCV,
    fieldValidations,
    setFieldValidations,
    isLoading,
    setIsLoading,
    isValidDate,
    handleEnterKeyPress,
    countryCodes,
    personalData,
    setPersonalData,
    cvSkimData,
  } = UserLogin();

  const { email, id, token } = userData || {};
  const hasPersonalData = personalData && Object.keys(personalData).length > 0;

  /* Menu for gender */
  const genderOptions = [
    { label: "Male", value: "M" },
    { label: "Female", value: "F" },
    { label: "Non Binary", value: "N" },
    { label: "Prefer not to say", value: "P" },
  ];

  /* Function to handle personal info */
  const validatePersonalInfo = (fields) => {
    const { first_name, last_name, current_address, nationality, phone_number, date_of_birth, city, cv_gender, genderOptions } = fields;
  
    const formattedPhoneNumber = `+${phone_number.replace(/\D/g, "")}`;
  
    return {
      first_name: Boolean(first_name?.trim() && first_name.length >= 3),
      last_name: Boolean(last_name?.trim() && last_name.length >= 3),
      current_address: Boolean(current_address?.trim() && current_address.length >= 5),
      nationality: Boolean(nationality?.trim()),
      phone_number: formattedPhoneNumber.length >= 12,
      date_of_birth: isValidDate(date_of_birth),
      city: Boolean(city?.trim() && city.length >= 3),
      cv_gender: genderOptions.some((option) => option.value === cv_gender),
      formattedPhoneNumber,
    };
  };
  
  const handlePersonalInfoSubmit = async (e) => {
    e.preventDefault();
  
    const validationResults = validatePersonalInfo({
      first_name,
      last_name,
      current_address,
      nationality,
      phone_number,
      date_of_birth,
      city,
      cv_gender,
      genderOptions,
    });
  
    const { formattedPhoneNumber, ...fieldValidationStatus } = validationResults;
    const isFormValid = Object.values(fieldValidationStatus).every((isValid) => isValid);
  
    setFieldValidations(fieldValidationStatus);
    console.log("Validation results:", fieldValidationStatus);
  
    if (!isFormValid) {
      console.warn("Form is not valid.");
      return;
    }
  
    setIsLoading(true);
  
    try {
      const response = await fetch(`${USER_PROFILE_URL}/personal-information/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({
          first_name,
          last_name,
          phone_number: formattedPhoneNumber,
          date_of_birth,
          city,
          state: state || null,
          zip: zip || null,
          current_address,
          cv_gender,
          nationality,
          user: id,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.statusText}`);
      }
  
      const data = await response.json();
      console.log("Request succeeded:", data);
  
      setShowPersonalInfoForm(false);
      setShowEducationForm(true);
      setActiveCV(1);
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
//new efficent way to merge data and cvskim data
const mergeData = (skimData, fetchedData) => ({
  first_name: skimData.first_name || fetchedData.first_name || "",
  last_name: skimData.last_name || fetchedData.last_name || "",
  email: skimData.email || fetchedData.email || "",
  phone_number: skimData.phone_number || fetchedData.phone_number || "",
  date_of_birth: skimData.date_of_birth || fetchedData.date_of_birth || "",
  cv_gender: skimData.cv_gender || fetchedData.cv_gender || "",
  current_address: skimData.current_address || fetchedData.current_address || "",
  city: skimData.city || fetchedData.city || "",
  zip: skimData.zip || fetchedData.zip || "",
  state: skimData.state || fetchedData.state || "",
});

useEffect(() => {
  if (cvSkimData) {
    setCVCredentials(mergeData(cvSkimData, {})); // Use empty object as default fallback
  }
  //eslint-disable-next-line react-hooks/exhaustive-deps
}, [cvSkimData]);

useEffect(() => {
  const fetchUserInfo = async () => {
    try {
      const response = await fetch(`${USER_PROFILE_URL}/personal-information/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });

      if (!response.ok) {
        console.error(`Failed to fetch user info: ${response.statusText}`);
        return;
      }

      const fetchedData = await response.json();
      setPersonalData(fetchedData);
      console.log("Fetched user info:", fetchedData);

      setCVCredentials(mergeData(cvSkimData, fetchedData));
    } catch (error) {
      console.error("Error while fetching user info:", error);
    }
  };

  if (token) fetchUserInfo();
  //eslint-disable-next-line react-hooks/exhaustive-deps
}, [token, cvSkimData]);

  /* Function to handle without auth personal info */
  const handleWithoutAuth = async (e) => {
    e.preventDefault();
    setActiveCV(1);
    setShowPersonalInfoForm(false);
    setShowEducationForm(true);
  };

  const today = new Date();
  const tenYearsAgo = new Date(today.getFullYear() - 10, today.getMonth(), today.getDate());
  const maxDate = tenYearsAgo.toISOString().split("T")[0];

  return (
    <>
      <form className="my-4" id="personal-info">
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="fname" className="form-label">
              {t("personal-fname")}
            </label>
            <div className="mb-3">
              <TextField
                name="first_name"
                sx={{
                  "& fieldset": { border: "none" },
                  background: "#F0F3F6",
                  borderRadius: "5px",
                }}
                value={first_name}
                onChange={onChangeCVCredentials}
                placeholder="e.g. Jhon"
                required
                id="first_name?"
                variant="outlined"
                fullWidth
                error={!fieldValidations.first_name && !(first_name || cvSkimData?.first_name)}
                helperText={
                  !fieldValidations.first_name &&
                  !(first_name || cvSkimData?.first_name) &&
                  !first_name.trim() &&
                  "First Name must be at least 3 characters long!"
                }
                onKeyDown={(event) => handleEnterKeyPress(event, "last_name")}
              />
            </div>
          </div>
          <div className="col-md-6">
            <label htmlFor="lname" className="form-label">
              {t("personal-lname")}
            </label>
            <div className="mb-3">
              <TextField
                name="last_name"
                sx={{
                  "& fieldset": { border: "none" },
                  background: "#F0F3F6",
                  borderRadius: "5px",
                }}
                value={last_name}
                onChange={onChangeCVCredentials}
                placeholder="e.g.Kalz"
                required
                id="last_name"
                variant="outlined"
                fullWidth
                error={!fieldValidations.last_name}
                helperText={
                  !fieldValidations.last_name &&
                  !last_name.trim() &&
                  "Last Name must be at least 3 characters long!"
                }
                onKeyDown={(event) => handleEnterKeyPress(event, "phone_number")}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="contactEmail" className="form-label">
              {t("personal-email")}
            </label>
            <div className="mb-3">
              <TextField
                name="email"
                sx={{
                  "& fieldset": { border: "none" },
                  background: "#F0F3F6",
                  borderRadius: "5px",
                }}
                value={email}
                id="contactEmail"
                variant="outlined"
                fullWidth
                disabled
              />
            </div>
          </div>

          <div className="col-md-6">
            <label htmlFor="phone_number" className="form-label">
              {t("personal-phone")}
            </label>
            <div className="mb-3">
              <div style={{ display: "flex" }}>
                <PhoneInput
                  id="phone_number"
                  name="phone_number"
                  country={"cw"}
                  isValid={(value, country) => {
                    if (value.match(/12345/)) {
                      return "Invalid value: " + value + ", " + country.name;
                    } else if (value.match(/1234/)) {
                      return false;
                    } else {
                      return true;
                    }
                  }}
                  value={phone_number}
                  onChange={(newPhone) =>
                    onChangeCVCredentials({ target: { name: "phone_number", value: newPhone } })
                  }
                  maxLength={20}
                  onKeyDown={(event) => handleEnterKeyPress(event, "date_of_birth")}
                />
              </div>
            </div>
            <span className="text-danger fs-6 m-0">
              {!fieldValidations.phone_number && "Please enter a valid phone number"}
            </span>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <label htmlFor="date_of_birth" className="form-label">
              {t("personal-dob")}
            </label>
            <div className="mb-3">
              <div className="mb-3">
                <TextField
                  name="date_of_birth"
                  sx={{
                    "& fieldset": { border: "none" },
                    background: "#F0F3F6",
                    borderRadius: "5px",
                    "& input::-webkit-calendar-picker-indicator": {
                      filter:
                        "invert(20%) sepia(100%) saturate(5000%) hue-rotate(260deg) brightness(80%) contrast(90%)",
                    },
                    "& input[type='date']::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type='date']::-webkit-clear-button": {
                      display: "none",
                    },
                  }}
                  value={date_of_birth || ""}
                  onChange={onChangeCVCredentials}
                  type="date"
                  required
                  id="date_of_birth"
                  variant="outlined"
                  fullWidth
                  error={!fieldValidations.date_of_birth}
                  helperText={
                    !fieldValidations.date_of_birth &&
                    !date_of_birth.trim() &&
                    "Please enter a valid date of birth."
                  }
                  onKeyDown={(event) => handleEnterKeyPress(event, "cv_gender")}
                  inputProps={{
                    max: maxDate,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <label htmlFor="gender" className="form-label">
              {t("personal-gender")}
            </label>
            <div className="mb-3">
              <TextField
                name="cv_gender"
                sx={{
                  "& fieldset": { border: "none" },
                  background: "#F0F3F6",
                  borderRadius: "5px",
                }}
                value={cv_gender}
                onChange={onChangeCVCredentials}
                placeholder="Select Gender"
                id="cv_gender"
                select
                variant="outlined"
                style={{ width: "100%" }}
                error={!fieldValidations.cv_gender}
                helperText={
                  !fieldValidations.cv_gender && !cv_gender.trim() && "Please select a gender"
                }
                onKeyDown={(event) => handleEnterKeyPress(event, "nationality")}
              >
                {genderOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <label htmlFor="nationality" className="form-label">
              {t("personal-nationality")}
            </label>
            <div className="mb-3">
              <TextField
                id="nationality"
                name="nationality"
                sx={{
                  "& fieldset": { border: "none" },
                  background: "#F0F3F6",
                  borderRadius: "5px",
                }}
                select
                variant="outlined"
                style={{ width: "100%" }}
                value={nationality || ""}
                onChange={onChangeCVCredentials}
                error={!fieldValidations.nationality}
                helperText={
                  !fieldValidations.nationality &&
                  !nationality?.trim() &&
                  "Please select a nationality."
                }
                onKeyDown={(event) => handleEnterKeyPress(event, "city")}
              >
                {Object.entries(countryCodes).map(([countryName, countryCode], index) => (
                  <MenuItem key={index} value={countryName}>
                    <ReactCountryFlag
                      countryCode={countryCode}
                      svg
                      style={{ marginRight: "8px" }}
                    />
                    {countryName}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>

          <div className="col-md-6">
            <label htmlFor="city" className="form-label">
              {t("personal-city")}
            </label>
            <div className="mb-3">
              <TextField
                name="city"
                sx={{
                  "& fieldset": { border: "none" },
                  background: "#F0F3F6",
                  borderRadius: "5px",
                }}
                value={city || cvSkimData?.city || ""}
                onChange={onChangeCVCredentials}
                required
                placeholder="e.g. Willemstad"
                id="city"
                variant="outlined"
                fullWidth
                error={!fieldValidations.city}
                helperText={
                  !fieldValidations.city && !city.trim() && "City must be 5 character long!"
                }
                onKeyDown={(event) => handleEnterKeyPress(event, "state")}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <label htmlFor="state" className="form-label">
              {t("personal-state")} (optional)
            </label>
            <div className="mb-3">
              <TextField
                name="state"
                sx={{
                  "& fieldset": { border: "none" },
                  background: "#F0F3F6",
                  borderRadius: "5px",
                }}
                value={state || null}
                onChange={onChangeCVCredentials}
                placeholder="e.g. Caribbean Sea"
                id="state"
                variant="outlined"
                fullWidth
                // error={!fieldValidations.state}
                // helperText={
                //   !fieldValidations.state &&
                //   !state.trim() &&
                //   "State must of atleast 3 character long!"
                // }
                onKeyDown={(event) => handleEnterKeyPress(event, "zip")}
              />
            </div>
          </div>
          <div className="col-md-6">
            <label htmlFor="zip" className="form-label">
              {t("personal-zip")} {countryCodes[nationality] ? "(optional)" : ""}
            </label>
            <div className="mb-3">
              <TextField
                name="zip"
                sx={{
                  "& fieldset": { border: "none" },
                  background: "#F0F3F6",
                  borderRadius: "5px",
                }}
                value={zip || null}
                onChange={onChangeCVCredentials}
                placeholder="e.g. 0000"
                id="zip"
                variant="outlined"
                fullWidth
                inputMode="numeric"
                type="number"
                // error={!fieldValidations.zip}
                // helperText={
                //   !fieldValidations.zip &&
                //   !zip.trim() &&
                //   "Enter a Valid 5-digit ZIP code!"
                // }
                onKeyDown={(event) => handleEnterKeyPress(event, "current_address")}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <label htmlFor="current_address" className="form-label">
              {t("personal-address")}
            </label>
            <div className="mb-3">
              <TextField
                name="current_address"
                sx={{
                  "& fieldset": { border: "none" },
                  background: "#F0F3F6",
                  borderRadius: "5px",
                }}
                value={current_address || ""}
                onChange={onChangeCVCredentials}
                required
                id="current_address"
                variant="outlined"
                fullWidth
                error={!fieldValidations.current_address}
                helperText={
                  !fieldValidations.current_address &&
                  // !current_address.trim() &&
                  "current_address must be 5 character long!"
                }
              />
            </div>
          </div>
        </div>

        <div className="d-flex aligns-items-center justify-content-end gap-2 ">
          <div className="d-flex align-items-center mt-2">
            <button type="submit" className="login-form-button" onClick={handlePersonalInfoSubmit}>
              {t("btn-add")}
            </button>
          </div>
          {hasPersonalData ? (
            <div className="d-flex align-items-center mt-2">
              <button type="submit" className="login-form-button" onClick={handleWithoutAuth}>
                {t("btn-next")}
              </button>
            </div>
          ) : null}
        </div>
      </form>
      {isLoading ? <Progress /> : null}
    </>
  );
}
