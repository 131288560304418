import React from 'react'
import onboardiq from "../../assets/img/onbLogoPng.png"

const FetchingLoadingOverlay = () => {
    return (
        <div class="position-fixed bg-white d-flex flex-column top-0 start-0 vw-100 vh-100  opacity-10 d-flex align-items-center justify-content-center" style={{
            zIndex: 999
        }}>
            <div className='d-flex align-items-center justify-content-center flex-column'>
                <img src={onboardiq} alt='logo' style={{
                    width: "300px",
                    height: "auto"
                }} />
                <p className='mt-2 fs-5'>Fetching...</p>
            </div>
        </div>
    )
}

export default FetchingLoadingOverlay