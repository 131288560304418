import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { UserLogin } from "../../../context/AuthContext";
// import vocabularyImg from "../../../assets/img/analytics.png";
import { useTranslation } from "react-i18next";

function ReportSection4() {
  const { t } = useTranslation();
  const { selectedAudioReport, levelCodes, levelNames } = UserLogin();
  const wordsByLevel = selectedAudioReport?.analysis_data?.detailedMetrics?.WordsByLevel;
  const percentageByLevel = selectedAudioReport?.analysis_data?.detailedMetrics?.PercentageByLevel;
  const activeVocabulary =
    selectedAudioReport?.analysis_data?.detailedMetrics?.vocabulary?.activeVocabulary;
  const highestLevel = selectedAudioReport?.analysis?.proficiencyLevel;
  const levelCode = levelCodes[highestLevel] || "A1";
  const levelOrder = Object.values(levelCodes);
  const currentLevelIndex = levelOrder.indexOf(levelCode);
  const nextLevelCode =
    currentLevelIndex >= 0 && currentLevelIndex < levelOrder.length - 1
      ? levelOrder[currentLevelIndex + 1]
      : null;
  const totalUniqueWords =
    selectedAudioReport?.analysis_data?.detailedMetrics?.vocabulary?.uniqueWords;
  const rareWords = selectedAudioReport?.analysis_data?.detailedMetrics?.vocabulary?.rareWords;
  const frequentlyUsedWords =
    selectedAudioReport?.analysis_data?.detailedMetrics?.vocabulary?.frequentlyUsedWords;

    console.log("selectedAudioReport: ", selectedAudioReport);
  const [chartData, setChartData] = useState({
    series: [],
    color: ["#43ADDB", "green", "yellow", "orange", "purple"],
    options: {
      chart: {
        type: "polarArea",
        width: 380,
      },
      labels: [],
      fill: {
        opacity: 1,
      },
      stroke: {
        width: 0,
      },
      legend: {
        formatter: function (val, opts) {
          return `${opts.w.globals.series[opts.seriesIndex]}% — ${val}`;
        },
      },
      yaxis: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 1280,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  const [hasChartData, setHasChartData] = useState(false);

  useEffect(() => {
    if (percentageByLevel) {
      const newSeries = [];
      const newLabels = [];
      const colors = []; 
      Object.entries(percentageByLevel).forEach(([levelCode, percentage], index) => {
        const percentageNumber = percentage !== "N/A" ? parseFloat(percentage) : 0;
        newSeries.push(percentageNumber);
        const levelName = levelNames[levelCode];
        const label = `${levelName} (${levelCode})`;
        newLabels.push(label);
      });

      const hasData = newSeries.some((value) => value !== 0);
      console.log("new series data:",newSeries)
      setHasChartData(hasData);

      setChartData((prevData) => ({
        ...prevData,
        series: newSeries,
        options: {
          ...prevData.options,
          labels: newLabels,
          colors: colors.slice(0, newSeries.length),
          dataLabels: {
            enabled: false,
          },
        },
      }));
    }
  }, [percentageByLevel, levelNames]);

  return (
    <div>
      {/* Vocabulary content Statistics & Level Table*/}
      <div className="row mb-3">
        <div className="col-md-12">
          <div
            className="card"
            style={{ borderRadius: "22px", padding: "20px", border: "2px solid #b7b7b7" }}
          >
            <div className="card-body">
              <div className="d-flex flex-md-row gap-2 px-1 pe-2 flex-column align-items-md-start align-items-center">
                {/* Pie chart */}
                <div className="col-md-6 col-lg-5 col-sm-12">
                  <div style={{ fontWeight: "bold", padding: "15px 0" }}>
                    <h3 className="card-title mx-2 text-start" style={{ fontWeight: "600" }}>
                      {t("vocabulary-statistics")}
                    </h3>
                  </div>
                  {hasChartData ? (
                    <div className="report-chart col-12 d-flex flex-column align-items-center px-0">
                      <ReactApexChart
                        options={chartData.options}
                        series={chartData.series}
                        type="polarArea"
                        height={300}
                        width={500}
                      />
                    </div>
                  ) : (
                    <p className="card-text mx-3">{t("vocabulary-statistics-data-is-n-a")}</p>
                  )}
                </div>

                {/* word level table */}
                <div
                  className="col-md-6 col-lg-7 col-sm-12"
                  style={{
                    borderRadius: "14px",
                  }}
                >
                  <div style={{ fontWeight: "bold", padding: "12px 0" }}>
                    <h3 className="card-title mx-2" style={{ fontWeight: "600" }}>
                      {t("word-sample-by-level")}
                    </h3>
                  </div>
                  <div className="table-responsive">
                    <table className="table" style={{ color: "#606070", borderRadius: "18px" }}>
                      <thead>
                        <tr>
                          <th style={{ marginTop: "10%" }} scope="col">
                            {t("level")}
                          </th>
                          <th scope="col" style={{ padding: "0 0 7px 50px", marginTop: "10%" }}>
                            {t("words")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {wordsByLevel &&
                          Object.keys(wordsByLevel).map((level, index) => (
                            <tr key={index} className={`table ${index % 2 !== 0 ? "bg" : ""}`}>
                              <th scope="row">{levelCodes[level] || level}</th>
                              <td style={{ padding: "5px 0 5px 50px" }}>
                                {wordsByLevel[level].join(", ")}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* Vocabulary content 4 small div's */}
            </div>
          </div>
          <div className="grid-container-voc mb-3 mt-3">
            <div className="grid-item-voc card">
              <div className="card-body">
                <p className="voc-titles">{t("active-vocabulary")}</p>
                <h5 className="card-title" style={{ color: "#56367F" }}>
                  <b className="fs-3">
                    {activeVocabulary}&nbsp;<sub className="text-dark fs-6">{t("words")}</sub>
                  </b>
                </h5>
                <p className="card-text">
                  {t("corresponds-to-level")} {levelCode}, {t("the-next-level")}{" "}
                  {nextLevelCode ? `${nextLevelCode}` : "is not defined"} {t("starts-with")}{" "}
                  {activeVocabulary}&nbsp; {t("words")}
                </p>
              </div>
            </div>

            <div className="grid-item-voc card">
              <div className="card-body">
                <p className="voc-titles">{t("unique-words")}</p>
                <h5 className="card-title" style={{ color: "#56367F" }}>
                  <b className="fs-3">
                    {totalUniqueWords}&nbsp;<sub className="text-dark fs-6">{t("words")}</sub>
                  </b>
                </h5>
                <p className="card-text">{t("that-are-used-only-once-in-your-complete-speech")}</p>
              </div>
            </div>

            <div className="grid-item-voc card">
              <div className="card-body">
                <p className="voc-titles">{t("rare-words")}</p>
                <h5 className="card-title" style={{ color: "#56367F" }}>
                  <b className="fs-3">{rareWords}%&nbsp;</b>
                </h5>
                <p className="card-text">
                  {t("words-that-are-not-among-the-5000-most-common-english")}
                </p>
              </div>
            </div>

            <div className="grid-item-voc card">
              <div className="card-body">
                <p className="voc-titles">{t("frequently_used_words")}</p>
                <h5 className="card-title" style={{ color: "#56367F" }}>
                  <b className="fs-3">
                    {frequentlyUsedWords}&nbsp;<sub className="text-dark fs-6">{t("words")}</sub>
                  </b>
                </h5>
                <p className="card-text">
                  {t("words-that-are-among-the-2000-most-frequently-used-english-words")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportSection4;
