import React, { createContext, useContext, useEffect, useState, useRef } from "react";
import { Snackbar, Alert } from "@mui/material";
import { isValid } from "date-fns";

import usflagImg from "../assets/img/us_flag.png";
import dutchflagImg from "../assets/img/dutch_flag.png";
import spanishflagImg from "../assets/img/sp_flag.png";
import popiaflagImg from "../assets/img/popia_flag.png";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [toTop, settoTop] = useState(false);
  const [nav, setNav] = useState(false);
  const [isPasswordInput, setIsPasswordInput] = useState(false);
  const [stickyNav, setstickyNav] = useState(false);

  const [activeCV, setActiveCV] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [isTypingPassword, setIsTypingPassword] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [timerValue, setTimerValue] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  const [reportData, setReportData] = useState([]);
  const [selectedAudioReport, setSelectedAudioReport] = useState([]);
  const [interviewName, setInterviewName] = useState([]);

  const storedToken = localStorage.getItem("token");
  const [token, setToken] = useState(storedToken);
  const storedUserData = localStorage.getItem("userData");
  const [cvSkimData, setCVSkimData] = useState("");
  const [cvSkimEduData, setCVSkimEduData] = useState("");
  const [cvSkimWorkData, setCVSkimWorkData] = useState("");
  const [testName, setTestName] = useState("");
  // const [submissionId, setSubmissionId] = useState('');
  const [piReportData, setPIReportData] = useState("");

  let initialUserData;
  try {
    initialUserData = storedUserData ? JSON.parse(storedUserData) : {};
  } catch (error) {
    console.error("Error parsing storedUserData:", error);
    initialUserData = {};
  }
  const [userData, setUserData] = useState(initialUserData);
  const [langChange, setLangChange] = useState(localStorage.getItem("userLanguage") || "en");

  const [displayedFlag, setDisplayedFlag] = useState(() => {
    switch (langChange) {
      case "en":
        return usflagImg;
      case "sp":
        return spanishflagImg;
      case "du":
        return dutchflagImg;
      case "pa":
        return popiaflagImg;
      default:
        return usflagImg;
    }
  });

  const [barStatus, setBarStatus] = useState(0);
  const [heroBtnStatus, setHeroBtnStatus] = useState({
    cv: true,
    speaking: false,
    predictive: false,
  });

  const [url, setUrl] = useState([]);

  const [passwordRequirementsError, setPasswordRequirementsError] = useState(false);

  const [isNextClicked, setIsNextClicked] = useState(false);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [interviewQuestions, setInterviewQuestions] = useState([]);
  const totalQuestions = interviewQuestions?.length;
  const [showEndButton, setShowEndButton] = useState(true);
  const [showSubmitButton, setShowSubmitButton] = useState(false);

  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const [timeSpentOnCurrentQuestion, setTimeSpentOnCurrentQuestion] = useState(0);
  const [recordedAudioDuration, setRecordedAudioDuration] = useState(0);
  const currentQuestionId = interviewQuestions[currentQuestionIndex]?.id;
  const [modalOpen, setModalOpen] = useState(false);

  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [race, setRace] = useState("");
  const [education, setEducation] = useState("");
  const [radio11, setRadio11] = useState("");
  const [radio12, setRadio12] = useState("");
  const [radio13, setRadio13] = useState("");
  const [checkbox1_pi, setCheckbox1_pi] = useState([]);
  const [checkbox2_pi, setCheckbox2_pi] = useState([]);

  /* CV fields */
  const [selectedPhoneCode, setSelectedPhoneCode] = useState("+599");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [fileToUpload, setFileToUpload] = useState(null);
  const [showPersonalInfoForm, setShowPersonalInfoForm] = useState(true);
  const [showEducationForm, setShowEducationForm] = useState(false);
  const [showWorkExperienceForm, setShowWorkExperienceForm] = useState(false);
  const [personalData, setPersonalData] = useState([]);
  const [educationData, setEducationData] = useState([]);
  const [workData, setWorkData] = useState([]);
  const [fileSelected, setFileSelected] = useState(false);
  const [selectedInterview, setSelectedInterview] = useState("");
  const [initiallySelectedInterview, setInitiallySelectedInterview] = useState(selectedInterview);

  const [cvCredentials, setCVCredentials] = useState({
    first_name: "",
    last_name: "",
    date_of_birth: "",
    nationality: "",
    city: "",
    state: "",
    zip: "",

    phone_number: "",
    current_address: "",
    cv_gender: "",

    title: "",
    institute_name: "",
    education_level: "",
    start_date: "",
    end_date: "",
    score: "",

    company_name: "",
    job_title: "",
    employment_type: "",
    work_method: "",
    work_start_date: "",
    work_end_date: "",
    description: "",
    yoe: "",
  });

  const [fieldValidations, setFieldValidations] = useState({
    first_name: true,
    last_name: true,
    date_of_birth: true,
    nationality: true,
    city: true,
    state: true,
    zip: true,

    phone_number: true,
    current_address: true,
    cv_gender: true,

    title: true,
    institute_name: true,
    education_level: true,
    start_date: true,
    end_date: true,
    score: true,

    company_name: true,
    job_title: true,
    employment_type: true,
    work_method: true,
    work_start_date: true,
    work_end_date: true,
    description: true,
    yoe: true,
  });

  const onChangeCVCredentials = (e) => {
    setCVCredentials({ ...cvCredentials, [e.target.name]: e.target.value });
  };

  /* Menu for educational level */
  const educationalLevelOptions = [
    { label: "High School", value: "HS" },
    { label: "Associate Degree", value: "AD" },
    { label: "Bachelor's Degree", value: "BD" },
    { label: "Master's Degree", value: "MD" },
    { label: "Professional Degree", value: "PD" },
    { label: "Ph.D. or Doctorate", value: "PHD" },
    { label: "Diploma", value: "DIP" },
    { label: "Certification", value: "CERT" },
    { label: "Vocational Training", value: "VC" },
    { label: "Postgraduate Residency", value: "PR" },
    { label: "Fellowship", value: "FC" },
    { label: "Other", value: "OTH" },
  ];

  /* Menu for  Employment Type */
  const employmentTypeOptions = [
    { label: "Full-Time", value: "FT" },
    { label: "Part-Time", value: "PT" },
    { label: "Internship", value: "INT" },
    { label: "Contract", value: "CON" },
    { label: "Temporary", value: "TMP" },
    { label: "Other", value: "OTH" },
  ];

  /* Menu for work method Type */
  const workMethodOptions = [
    { label: "On-Site", value: "ONS" },
    { label: "Remote", value: "REM" },
    { label: "Hybrid", value: "HYB" },
  ];

  /* Menu for age */
  const selectedAge = [
    { label: "17 or younger", value: "17_or_younger" },
    { label: "18-19", value: "18-19" },
    { label: "20-29", value: "20-29" },
    { label: "30-39", value: "30-39" },
    { label: "40-49", value: "40-49" },
    { label: "50-59", value: "50-59" },
    { label: "60-69", value: "60-69" },
    { label: "70 or older", value: "70_or_older" },
    {
      label: "I do not wish to disclose.",
      value: "not_disclosed.",
    },
  ];

  /* Menu for race */
  const selectedRace = [
    { label: "Asian", value: "asian" },
    { label: "White", value: "white" },
    { label: "Black", value: "black" },
    { label: "Hispanic or Latino", value: "hispanic_latino" },
    {
      label: "Middle Eastern and North African",
      value: "middle_eastern_north_african",
    },
    {
      label: "Native American and Alaska Native",
      value: "native_american_alaskan_native",
    },
    {
      label: "Native Hawaiian and Other Pacific Islander",
      value: "native_hawaiian_pacific_islander",
    },
    { label: "Two or More Races", value: "two_or_more_races" },
    { label: "I do not wish to disclose", value: "not_disclosed" },
  ];

  /* Zip code validation / date validation */
  const isValidZip = (zip) => /^[0-9]{5}$/.test(zip);
  const isValidDate = (dateOfBirth) => {
    return isValid(new Date(dateOfBirth));
  };

  /***** Functions to format timer for interview ******/
  useEffect(() => {
    let intervalId;
    if (isTimerRunning) {
      intervalId = setInterval(() => {
        setTimerValue((prevValue) => {
          if (prevValue >= 3600) {
            setIsTimerRunning(false);
            return 3600;
          }
          return prevValue + 1;
        });
      }, 1000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [isTimerRunning]);

  /***** Validation Checks ******/
  const isValidObjField = (obj) => {
    return Object.values(obj).every((value) => value.trim());
  };

  const updateError = (error, stateUpdater) => {
    stateUpdater(error);
    setTimeout(() => {
      stateUpdater("");
    }, 2500);
  };

  /***** Showing Toast/Alert ******/
  const showToast = (message) => {
    setToastMessage(message);
    setIsToastOpen(true);
  };

  const handleToastClose = () => {
    setIsToastOpen(false);
  };

  const handleErrorClose = () => {
    setIsErrorOpen(false);
  };

  /* Function to capitalize first letter of name */
  const capitalizeFirst = (str) => {
    if (typeof str === "string") {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str;
  };

  /* Descripiton for each Levels */
  const levelDescriptions = {
    Beginner:
      "At the beginner level, individuals are starting to grasp basic conversational phrases. They can exchange simple greetings and engage in uncomplicated discussions about topics like the weather, daily routines, and personal hobbies. While they may stumble occasionally, they are eager to learn and improve their ability to participate in everyday chit-chat with friends and acquaintances.",

    Elementary:
      "Elementary-level practitioners are gaining confidence in their conversational skills. They can comfortably initiate and sustain dialogues on familiar subjects, including personal interests, favorite foods, and leisure activities. While they might occasionally seek help with complex vocabulary, they're well on their way to becoming adept at handling light-hearted exchanges and social interactions.",

    Intermediate:
      "Individuals at the intermediate level of exhibit proficiency in engaging discussions. They can confidently express opinions, share experiences, and discuss a broad array of topics, such as travel destinations, recent movies, and upcoming plans. Their ability to navigate conversations fluidly and respond thoughtfully makes them valuable contributors to social gatherings and casual conversations.",

    Upperintermediate:
      "At the upper-intermediate level, enthusiasts display finesse in steering conversations. They can delve into deeper subjects, such as cultural trends, personal goals, and societal issues, while maintaining a comfortable and engaging atmosphere. Their conversational prowess allows them to connect with others on a meaningful level, making them sought-after conversationalists.",

    Advanced:
      "Advanced practitioners are virtuosos in the art of conversation. They effortlessly navigate intricate topics, including philosophy, art, and global affairs, while weaving in personal anecdotes and insightful perspectives. Their eloquence and charm make them captivating interlocutors who can turn any exchange into a memorable and enriching experience.",

    Proficiency:
      "At the proficiency level, individuals demonstrate exceptional mastery in handling customer interactions. They can navigate complex conversations with ease, adapting their tone and language to suit any situation. Their deep understanding of customer needs and their ability to resolve intricate issues make them invaluable assets in delivering high-quality service and fostering positive client relationships.",
  };

  /* Display Highest Level in Polygon */
  const levelCodes = {
    Beginner: "A1",
    Elementary: "A2",
    Intermediate: "B1",
    upperintermediate: "B2",
    Advanced: "C1",
    Proficiency: "C2",
  };

  /***** Get and Store CSRF Token in localStorage ******/
  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }
  }, [token]);

  /***** Protected Routing ******/
  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   if (!token) {
  //     navigate("/Register");
  //   }
  // }, [navigate]);

  /* Modal to show on interview screen */
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const formatTimer = (seconds) => {
    const adjustedSeconds = seconds > 0 ? seconds - 1 : 0;
    const mins = Math.floor(adjustedSeconds / 60);
    const secs = adjustedSeconds % 60;
    const formattedMins = mins < 10 ? `0${mins}` : mins;
    const formattedSecs = secs < 10 ? `0${secs}` : secs;
    return `${formattedMins}:${formattedSecs}`;
  };

  const handleEnterKeyPress = (event, nextField) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const nextFieldElement = document.getElementById(nextField);
      if (nextFieldElement) {
        nextFieldElement.focus();
      }
    }
  };

  /* Function to map countries */
  const countryCodes = {
    Afghanistan: "AF",
    Albania: "AL",
    Algeria: "DZ",
    Andorra: "AD",
    Angola: "AO",
    "Antigua & Deps": "AG",
    Argentina: "AR",
    Armenia: "AM",
    Australia: "AU",
    Austria: "AT",
    Azerbaijan: "AZ",
    Bahamas: "BS",
    Bahrain: "BH",
    Bangladesh: "BD",
    Barbados: "BB",
    Belarus: "BY",
    Belgium: "BE",
    Belize: "BZ",
    Benin: "BJ",
    Bhutan: "BT",
    Bolivia: "BO",
    "Bosnia Herzegovina": "BA",
    Botswana: "BW",
    Brazil: "BR",
    Brunei: "BN",
    Bulgaria: "BG",
    Burkina: "BF",
    Burundi: "BI",
    Curaçao: "CW",
    Cambodia: "KH",
    Cameroon: "CM",
    Canada: "CA",
    "Cape Verde": "CV",
    "Central African Rep": "CF",
    Chad: "TD",
    Chile: "CL",
    China: "CN",
    Colombia: "CO",
    Comoros: "KM",
    Congo: "CG",
    "Congo {Democratic Rep}": "CD",
    "Costa Rica": "CR",
    Croatia: "HR",
    Cuba: "CU",
    Cyprus: "CY",
    "Czech Republic": "CZ",
    Denmark: "DK",
    Djibouti: "DJ",
    Dominica: "DM",
    "Dominican Republic": "DO",
    "East Timor": "TL",
    Ecuador: "EC",
    Egypt: "EG",
    "El Salvador": "SV",
    "Equatorial Guinea": "GQ",
    Eritrea: "ER",
    Estonia: "EE",
    Ethiopia: "ET",
    Fiji: "FJ",
    Finland: "FI",
    France: "FR",
    Gabon: "GA",
    Gambia: "GM",
    Georgia: "GE",
    Germany: "DE",
    Ghana: "GH",
    Greece: "GR",
    Grenada: "GD",
    Guatemala: "GT",
    Guinea: "GN",
    "Guinea-Bissau": "GW",
    Guyana: "GY",
    "Hong Kong": "HK",
    Haiti: "HT",
    Honduras: "HN",
    Hungary: "HU",
    Iceland: "IS",
    India: "IN",
    Indonesia: "ID",
    Iran: "IR",
    Iraq: "IQ",
    "Ireland {Republic}": "IE",
    Israel: "IL",
    Italy: "IT",
    "Ivory Coast": "CI",
    Jamaica: "JM",
    Japan: "JP",
    Jordan: "JO",
    Kazakhstan: "KZ",
    Kenya: "KE",
    Kiribati: "KI",
    "Korea North": "KP",
    "Korea South": "KR",
    Kosovo: "XK",
    Kuwait: "KW",
    Kyrgyzstan: "KG",
    Laos: "LA",
    Latvia: "LV",
    Lebanon: "LB",
    Lesotho: "LS",
    Liberia: "LR",
    Libya: "LY",
    Liechtenstein: "LI",
    Lithuania: "LT",
    Luxembourg: "LU",
    Macedonia: "MK",
    Madagascar: "MG",
    Malawi: "MW",
    Malaysia: "MY",
    Maldives: "MV",
    Mali: "ML",
    Malta: "MT",
    "Marshall Islands": "MH",
    Mauritania: "MR",
    Mauritius: "MU",
    Mexico: "MX",
    Micronesia: "FM",
    Moldova: "MD",
    Monaco: "MC",
    Mongolia: "MN",
    Montenegro: "ME",
    Morocco: "MA",
    Mozambique: "MZ",
    "Myanmar, {Burma}": "MM",
    Namibia: "NA",
    Nauru: "NR",
    Nepal: "NP",
    Netherlands: "NL",
    "New Zealand": "NZ",
    Nicaragua: "NI",
    Niger: "NE",
    Nigeria: "NG",
    Norway: "NO",
    Oman: "OM",
    Pakistan: "PK",
    Palau: "PW",
    Panama: "PA",
    "Papua New Guinea": "PG",
    Palestine: "PS",
    "Puerto Rico": "PR",
    Paraguay: "PY",
    Peru: "PE",
    Philippines: "PH",
    Poland: "PL",
    Portugal: "PT",
    Qatar: "QA",
    Romania: "RO",
    "Russian Federation": "RU",
    Rwanda: "RW",
    "St Kitts & Nevis": "KN",
    "St Lucia": "LC",
    "Saint Vincent & the Grenadines": "VC",
    Samoa: "WS",
    "San Marino": "SM",
    "Sao Tome & Principe": "ST",
    "Saudi Arabia": "SA",
    Senegal: "SN",
    Serbia: "RS",
    Seychelles: "SC",
    "Sierra Leone": "SL",
    Singapore: "SG",
    Slovakia: "SK",
    Slovenia: "SI",
    "Solomon Islands": "SB",
    Somalia: "SO",
    "South Africa": "ZA",
    "South Sudan": "SS",
    Spain: "ES",
    "Sri Lanka": "LK",
    Sudan: "SD",
    Suriname: "SR",
    Swaziland: "SZ",
    Sweden: "SE",
    Switzerland: "CH",
    Syria: "SY",
    Taiwan: "TW",
    Tajikistan: "TJ",
    Tanzania: "TZ",
    Thailand: "TH",
    Togo: "TG",
    Tonga: "TO",
    "Trinidad & Tobago": "TT",
    Tunisia: "TN",
    Turkey: "TR",
    Turkmenistan: "TM",
    Tuvalu: "TV",
    Uganda: "UG",
    Ukraine: "UA",
    "United Arab Emirates": "AE",
    "United Kingdom": "GB",
    "United States": "US",
    Uruguay: "UY",
    Uzbekistan: "UZ",
    Vanuatu: "VU",
    "Vatican City": "VA",
    Venezuela: "VE",
    Vietnam: "VN",
    Yemen: "YE",
    Zambia: "ZM",
    Zimbabwe: "ZW",
  };

  /* Phone codes */
  const phoneCodes = [
    "+599",
    "+855",
    "+237",
    "+92",
    "+238",
    "+1-345",
    "+236",
    "+235",
    "+56",
    "+86",
    "+53",
    "+61",
    "+57",
    "+269",
    "+243",
    "+242",
    "+682",
    "+506",
    "+225",
    "+385",
    "+357",
    "+420",
    "+45",
    "+253",
    "+1-767",
    "+1-809",
    "+670",
    "+593",
    "+20",
    "+503",
    "+240",
    "+291",
    "+372",
    "+251",
    "+500",
    "+298",
    "+679",
    "+358",
    "+33",
    "+594",
    "+689",
    "+241",
    "+220",
    "+995",
    "+49",
    "+233",
    "+350",
    "+30",
    "+299",
    "+1-473",
    "+590",
    "+1-671",
    "+502",
    "+224",
    "+245",
    "+592",
    "+509",
    "+504",
    "+852",
    "+36",
    "+354",
    "+91",
    "+62",
    "+98",
    "+964",
    "+353",
    "+972",
    "+39",
    "+1-876",
    "+81",
    "+962",
    "+7",
    "+254",
    "+686",
    "+850",
    "+82",
    "+965",
    "+996",
    "+856",
    "+371",
    "+961",
    "+266",
    "+231",
    "+218",
    "+423",
    "+370",
    "+352",
    "+853",
    "+389",
    "+261",
    "+265",
    "+60",
    "+960",
    "+223",
    "+356",
    "+692",
    "+596",
    "+222",
    "+230",
    "+269",
    "+52",
    "+691",
    "+373",
    "+377",
    "+976",
    "+1-664",
    "+212",
    "+258",
    "+95",
    "+264",
    "+674",
    "+977",
    "+31",
    "+687",
    "+64",
    "+505",
    "+227",
    "+234",
    "+683",
    "+672",
    "+1-670",
    "+47",
    "+968",
    "+680",
    "+970",
    "+507",
    "+675",
    "+595",
    "+51",
    "+63",
    "+48",
    "+351",
    "+1-787",
    "+1-939",
    "+974",
    "+262",
    "+40",
    "+250",
    "+290",
    "+1-869",
    "+1-758",
    "+508",
    "+1-784",
    "+685",
    "+378",
    "+239",
    "+966",
    "+221",
    "+248",
    "+232",
    "+65",
    "+421",
    "+386",
    "+677",
    "+252",
    "+27",
    "+34",
    "+94",
    "+249",
    "+597",
    "+268",
    "+46",
    "+41",
    "+963",
    "+886",
    "+992",
    "+255",
    "+66",
    "+690",
    "+676",
    "+1-868",
    "+216",
    "+90",
    "+993",
    "+1-649",
    "+688",
    "+256",
    "+380",
    "+971",
    "+44",
    "+1",
    "+598",
    "+998",
    "+678",
    "+418",
    "+58",
    "+84",
    "+1-284",
    "+1-340",
    "+681",
    "+967",
    "+260",
    "+263",
  ];

  const [value, setValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const levelNames = {
    A1: "Proficiency",
    A2: "Advanced",
    B1: "Upper-intermediate",
    B2: "Intermediate",
    C1: "Elementary",
    C2: "Beginner",
  };
  const [isRecordingClicked, setIsRecordingClicked] = useState(false);
  const [questionTimers, setQuestionTimers] = useState({});
  const [run, setRun] = useState(false);

  const [steps, setSteps] = useState([
    {
      target: ".tour-step-one",
      title: "Number of Questions",
      content: "Number questions for easy reference as you provide answers.",
      placement: "top",
    },
    {
      target: ".tour-step-two",
      title: "Interview Time",
      content: "Keep track of time with the timer to manage interview duration.",
      placement: "top",
    },

    {
      target: ".tour-step-three",
      title: "Question Description",
      content: "Use the question description for a quick overview of each question's topic.",
      placement: "auto",
    },
  ]);
  const spanishSteps = [
    {
      target: ".tour-step-one",
      title: "Número de Preguntas",
      content: "Numera las preguntas para fácil referencia mientras proporcionas respuestas.",
      placement: "top",
    },
    {
      target: ".tour-step-two",
      title: "Tiempo de Entrevista",
      content:
        "Mantén un registro del tiempo con el temporizador para administrar la duración de la entrevista.",
      placement: "top",
    },
    {
      target: ".tour-step-three",
      title: "Descripción de la Pregunta",
      content:
        "Utiliza la descripción de la pregunta para una visión general rápida del tema de cada pregunta.",
      placement: "auto",
    },
  ];
  const dutchSteps = [
    {
      target: ".tour-step-one",
      title: "Aantal Vragen",
      content: "Nummer vragen voor gemakkelijke referentie terwijl u antwoorden geeft.",
      placement: "top",
    },
    {
      target: ".tour-step-two",
      title: "Interviewtijd",
      content: "Houd de tijd bij met de timer om de interviewduur te beheren.",
      placement: "top",
    },
    {
      target: ".tour-step-three",
      title: "Vraagbeschrijving",
      content:
        "Gebruik de vraagbeschrijving voor een snel overzicht van het onderwerp van elke vraag.",
      placement: "auto",
    },
  ];
  const papiamentoSteps = [
    {
      target: ".tour-step-one",
      title: "Cantidad di Pregunta",
      content: "Numera pregunta pa facilidad di referencia mientras bo ta duna contesta.",
      placement: "top",
    },
    {
      target: ".tour-step-two",
      title: "Tempo di Entrevista",
      content: "Mantene registro di tempo cu e timer pa maneha duracion di entrevista.",
      placement: "top",
    },
    {
      target: ".tour-step-three",
      title: "Descripcion di Pregunta",
      content:
        "Uza e descripcion di pregunta pa un bista general rapido di e topico di cada pregunta.",
      placement: "auto",
    },
  ];

  const [selectedOrganizationId, setSelectedOrganizationId] = useState("59");
  const [piSubmission, setPISubmission] = useState();
  const [fetchingLoading, setFetchingLoading] = useState(false);

  return (
    <AuthContext.Provider
      value={{
        run,
        setRun,
        steps,
        setSteps,
        fetchingLoading, setFetchingLoading,
        isRecordingClicked,
        setIsRecordingClicked,
        questionTimers,
        setQuestionTimers,
        selectedOrganizationId,
        setSelectedOrganizationId,
        piReportData,
        setPIReportData,
        piSubmission,
        setPISubmission,

        value,
        setValue,
        levelNames,
        handleTabChange,
        displayedFlag,
        setDisplayedFlag,
        langChange,
        setLangChange,
        cvSkimData,
        setCVSkimData,
        cvSkimEduData,
        setCVSkimEduData,
        cvSkimWorkData,
        setCVSkimWorkData,

        userData,
        setUserData,
        heroBtnStatus,
        setHeroBtnStatus,
        url,
        setUrl,
        passwordRequirementsError,
        setPasswordRequirementsError,
        token,
        setToken,
        isValidObjField,
        updateError,
        showToast,
        error,
        setError,
        setIsErrorOpen,
        capitalizeFirst,
        isLoading,
        setIsLoading,
        showPassword,
        setShowPassword,
        isTypingPassword,
        setIsTypingPassword,
        passwordMismatch,
        setPasswordMismatch,
        stickyNav,
        setstickyNav,
        toTop,
        settoTop,
        nav,
        setNav,
        currentQuestionIndex,
        setCurrentQuestionIndex,
        handleEnterKeyPress,
        isPasswordInput,
        setIsPasswordInput,

        interviewQuestions,
        setInterviewQuestions,
        totalQuestions,
        activeCV,
        setActiveCV,
        cvCredentials,
        setCVCredentials,
        onChangeCVCredentials,
        showPersonalInfoForm,
        setShowPersonalInfoForm,
        showEducationForm,
        setShowEducationForm,
        showWorkExperienceForm,
        setShowWorkExperienceForm,
        fieldValidations,
        setFieldValidations,
        educationalLevelOptions,
        employmentTypeOptions,
        workMethodOptions,

        countryCodes,
        phoneCodes,
        selectedPhoneCode,
        setSelectedPhoneCode,
        selectedFileName,
        setSelectedFileName,
        fileToUpload,
        setFileToUpload,

        personalData,
        setPersonalData,
        educationData,
        setEducationData,
        workData,
        setWorkData,
        isValidZip,
        isValidDate,
        fileSelected,
        setFileSelected,
        barStatus,
        setBarStatus,

        selectedInterview,
        setSelectedInterview,
        initiallySelectedInterview,
        setInitiallySelectedInterview,

        showEndButton,
        setShowEndButton,
        showSubmitButton,
        setShowSubmitButton,
        isRecording,
        setIsRecording,
        isPaused,
        setIsPaused,
        recordedAudio,
        setRecordedAudio,
        mediaRecorderRef,
        chunksRef,
        timeSpentOnCurrentQuestion,
        setTimeSpentOnCurrentQuestion,
        recordedAudioDuration,
        setRecordedAudioDuration,
        currentQuestionId,
        isNextClicked,
        setIsNextClicked,
        toggleModal,
        modalOpen,
        setModalOpen,

        interviewName,
        setInterviewName,
        reportData,
        setReportData,

        selectedAudioReport,
        setSelectedAudioReport,
        spanishSteps,
        dutchSteps,
        papiamentoSteps,
        selectedAge,
        selectedRace,
        timerValue,
        setTimerValue,
        isTimerRunning,
        setIsTimerRunning,
        formatTimer,
        levelDescriptions,
        levelCodes,
        testName,
        setTestName,
        // submissionId,
        // setSubmissionId,

        age,
        gender,
        race,
        education,
        radio11,
        radio12,
        radio13,
        checkbox1_pi,
        checkbox2_pi,
        setCheckbox1_pi,
        setCheckbox2_pi,

        setAge,
        setGender,
        setRace,
        setEducation,
        setRadio11,
        setRadio12,
        setRadio13,
      }}
    >
      {children}

      {/* Success Toast Component */}
      <Snackbar
        open={isToastOpen}
        autoHideDuration={3000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" onClose={handleToastClose}>
          {toastMessage}
        </Alert>
      </Snackbar>

      {/* Error Toast Component */}
      <Snackbar
        open={isErrorOpen}
        autoHideDuration={3000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error" onClose={handleErrorClose}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </AuthContext.Provider>
  );
};

export const UserLogin = () => useContext(AuthContext);

export default AuthProvider;
