import React, { useState } from "react";
import Navbar from "../Home/HomeSections/Section1/Navbar";
import cdsLogo from "../../assets/img/onbLogoPng.png";
import { TextField, MenuItem } from "@mui/material";
import call from "../../assets/img/callUs.png";
import emailIcon from "../../assets/img/mailIcon.png";
import support from "../../assets/img/supportIcon.png";
import { useNavigate } from "react-router";
import "../../App.css";
import Swal from "sweetalert2";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";

function Contact() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua & Deps",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Central African Rep",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo {Democratic Rep}",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland {Republic}",
    "Israel",
    "Italy",
    "Ivory Coast",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea North",
    "Korea South",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar, {Burma}",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "St Kitts & Nevis",
    "St Lucia",
    "Saint Vincent & the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome & Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Togo",
    "Tonga",
    "Trinidad & Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  function getCountryCode(countryName) {
    const countryCodes = {
      Afghanistan: "AF",
      Albania: "AL",
      Algeria: "DZ",
      Andorra: "AD",
      Angola: "AO",
      "Antigua & Deps": "AG",
      Argentina: "AR",
      Armenia: "AM",
      Australia: "AU",
      Austria: "AT",
      Azerbaijan: "AZ",
      Bahamas: "BS",
      Bahrain: "BH",
      Bangladesh: "BD",
      Barbados: "BB",
      Belarus: "BY",
      Belgium: "BE",
      Belize: "BZ",
      Benin: "BJ",
      Bhutan: "BT",
      Bolivia: "BO",
      "Bosnia Herzegovina": "BA",
      Botswana: "BW",
      Brazil: "BR",
      Brunei: "BN",
      Bulgaria: "BG",
      Burkina: "BF",
      Burundi: "BI",
      Cambodia: "KH",
      Cameroon: "CM",
      Canada: "CA",
      "Cape Verde": "CV",
      "Central African Rep": "CF",
      Chad: "TD",
      Chile: "CL",
      China: "CN",
      Colombia: "CO",
      Comoros: "KM",
      Congo: "CG",
      "Congo {Democratic Rep}": "CD",
      "Costa Rica": "CR",
      Croatia: "HR",
      Cuba: "CU",
      Cyprus: "CY",
      "Czech Republic": "CZ",
      Denmark: "DK",
      Djibouti: "DJ",
      Dominica: "DM",
      "Dominican Republic": "DO",
      "East Timor": "TL",
      Ecuador: "EC",
      Egypt: "EG",
      "El Salvador": "SV",
      "Equatorial Guinea": "GQ",
      Eritrea: "ER",
      Estonia: "EE",
      Ethiopia: "ET",
      Fiji: "FJ",
      Finland: "FI",
      France: "FR",
      Gabon: "GA",
      Gambia: "GM",
      Georgia: "GE",
      Germany: "DE",
      Ghana: "GH",
      Greece: "GR",
      Grenada: "GD",
      Guatemala: "GT",
      Guinea: "GN",
      "Guinea-Bissau": "GW",
      Guyana: "GY",
      Haiti: "HT",
      Honduras: "HN",
      Hungary: "HU",
      Iceland: "IS",
      India: "IN",
      Indonesia: "ID",
      Iran: "IR",
      Iraq: "IQ",
      "Ireland {Republic}": "IE",
      Israel: "IL",
      Italy: "IT",
      "Ivory Coast": "CI",
      Jamaica: "JM",
      Japan: "JP",
      Jordan: "JO",
      Kazakhstan: "KZ",
      Kenya: "KE",
      Kiribati: "KI",
      "Korea North": "KP",
      "Korea South": "KR",
      Kosovo: "XK",
      Kuwait: "KW",
      Kyrgyzstan: "KG",
      Laos: "LA",
      Latvia: "LV",
      Lebanon: "LB",
      Lesotho: "LS",
      Liberia: "LR",
      Libya: "LY",
      Liechtenstein: "LI",
      Lithuania: "LT",
      Luxembourg: "LU",
      Macedonia: "MK",
      Madagascar: "MG",
      Malawi: "MW",
      Malaysia: "MY",
      Maldives: "MV",
      Mali: "ML",
      Malta: "MT",
      "Marshall Islands": "MH",
      Mauritania: "MR",
      Mauritius: "MU",
      Mexico: "MX",
      Micronesia: "FM",
      Moldova: "MD",
      Monaco: "MC",
      Mongolia: "MN",
      Montenegro: "ME",
      Morocco: "MA",
      Mozambique: "MZ",
      "Myanmar, {Burma}": "MM",
      Namibia: "NA",
      Nauru: "NR",
      Nepal: "NP",
      Netherlands: "NL",
      "New Zealand": "NZ",
      Nicaragua: "NI",
      Niger: "NE",
      Nigeria: "NG",
      Norway: "NO",
      Oman: "OM",
      Pakistan: "PK",
      Palau: "PW",
      Panama: "PA",
      "Papua New Guinea": "PG",
      Paraguay: "PY",
      Peru: "PE",
      Philippines: "PH",
      Poland: "PL",
      Portugal: "PT",
      Qatar: "QA",
      Romania: "RO",
      "Russian Federation": "RU",
      Rwanda: "RW",
      "St Kitts & Nevis": "KN",
      "St Lucia": "LC",
      "Saint Vincent & the Grenadines": "VC",
      Samoa: "WS",
      "San Marino": "SM",
      "Sao Tome & Principe": "ST",
      "Saudi Arabia": "SA",
      Senegal: "SN",
      Serbia: "RS",
      Seychelles: "SC",
      "Sierra Leone": "SL",
      Singapore: "SG",
      Slovakia: "SK",
      Slovenia: "SI",
      "Solomon Islands": "SB",
      Somalia: "SO",
      "South Africa": "ZA",
      "South Sudan": "SS",
      Spain: "ES",
      "Sri Lanka": "LK",
      Sudan: "SD",
      Suriname: "SR",
      Swaziland: "SZ",
      Sweden: "SE",
      Switzerland: "CH",
      Syria: "SY",
      Taiwan: "TW",
      Tajikistan: "TJ",
      Tanzania: "TZ",
      Thailand: "TH",
      Togo: "TG",
      Tonga: "TO",
      "Trinidad & Tobago": "TT",
      Tunisia: "TN",
      Turkey: "TR",
      Turkmenistan: "TM",
      Tuvalu: "TV",
      Uganda: "UG",
      Ukraine: "UA",
      "United Arab Emirates": "AE",
      "United Kingdom": "GB",
      "United States": "US",
      Uruguay: "UY",
      Uzbekistan: "UZ",
      Vanuatu: "VU",
      "Vatican City": "VA",
      Venezuela: "VE",
      Vietnam: "VN",
      Yemen: "YE",
      Zambia: "ZM",
      Zimbabwe: "ZW",
    };

    return countryCodes[countryName] || "Unknown";
  }

  const currencies = countries.map((country, index) => ({
    value: index.toString(),
    label: country,
  }));

  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    msg: "",
    companyName: "",
    country: "",
  });
  const { name, email, msg, companyName, country } = formData;

  const validateName = (name) => {
    if (!name) {
      return "Name is required";
    }
    const specialChars = /[!@#$%^&*(),.?":{}|<>]/;
    if (specialChars.test(name) || /\d/.test(name)) {
      return "Special characters or digits are not allowed!";
    }

    // Check if the name contains only white spaces
    if (/^\s+$/.test(name)) {
      return "Name should not be empty or contain only white spaces";
    }
    return null;
  };

  const validateEmail = (email) => {
    if (!email || email.trim() === "") {
      return "Email is invalid";
    }
    // Basic email format validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return "Invalid email format";
    }
    return null;
  };

  const validateCompanyName = (companyName) => {
    if (!companyName || companyName.trim() === "") {
      return "Company Name is required";
    }
    return null;
  };

  const validateMsg = (msg) => {
    if (!msg || msg.trim() === "") {
      return "Message is required";
    }
    return null;
  };

  const validateCountry = (country) => {
    if (!country || country.trim() === "") {
      return "Country is required";
    }
    return null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};

    const nameError = validateName(formData.name);
    const emailError = validateEmail(formData.email);
    const msgError = validateMsg(formData.msg);
    const companyNameError = validateCompanyName(formData.companyName);
    const countryError = validateCountry(formData.country);

    if (nameError) errors.name = nameError;
    if (emailError) errors.email = emailError;
    if (msgError) errors.msg = msgError;
    if (companyNameError) errors.companyName = companyNameError;
    if (countryError) errors.country = countryError;

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    submitContactInfo();
  };

  const submitContactInfo = async () => {
    // no endpoint yet for contact page
    //add a delay of 2 seconds
    setTimeout(() => {
      Swal.fire({
        icon: "success",
        title: "Form submitted",
        text: "You will hear from us soon!",
        html: `
        <p>You will hear from us soon!</p> 
        <button id="swal-ok-button" class="login-form-button swal-button w-75">
          OK
        </button>
      `,
        showConfirmButton: false,
        didOpen: () => {
          document.getElementById("swal-ok-button").addEventListener("click", () => {
            Swal.close();
          });
        },
      });
    }, 3000);
  };

  return (
    <>
      <Navbar />
      <div style={{ position: "relative",display:"flex",
        justifyContent:"center"
       }}>
        {/* Contact Form */}
        <div
          className="contact-form-main"
          style={{
            padding: "7% 10%",
            backgroundColor: "#ffffff",
            borderRadius: "8px",
            margin: "auto 10px",
            display: "block",
            alignItems: "center",
            color: "black",
            position: "absolute",
            top: "80%",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
          }}
        >
          <img
            src={cdsLogo}
            alt="Logo"
            width="70%"
            height="auto"
            style={{
              margin: "auto ",
              display: "block",
              textAlign: "center",
              alignItems: "center",
              maxWidth: "300px",
            }}
          />
          <form className="my-5 mb-0">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="name" className="form-label">
                  {t("contact-your-name")}*
                </label>
                <div className="mb-3">
                  <TextField
                    required
                    id="name"
                    sx={{
                      "& fieldset": { border: "none" },
                      background: "#F0F3F6",
                      borderRadius: "5px"
                    }}
                    value={formData.name}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    error={errors.name ? true : false}
                    helperText={errors.name && `${errors.name}`}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="contactEmail" className="form-label">
                  {t("contact-email")}*
                </label>
                <div className="mb-3">
                  <TextField
                    value={formData.email}
                    required
                    sx={{
                      "& fieldset": { border: "none" },
                      background: "#F0F3F6",
                      borderRadius: "5px"
                    }}
                    id="contactEmail"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    error={errors.email ? true : false}
                    helperText={errors.email && `${errors.email}`}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="company-name" className="form-label">
                  {t("contact-company-name")}*
                </label>
                <div className="mb-3">
                  <TextField
                    value={formData.companyName}
                    required
                    sx={{
                      "& fieldset": { border: "none" },
                      background: "#F0F3F6",
                      borderRadius: "5px"
                    }}
                    id="company-name"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => setFormData({ ...formData, companyName: e.target.value })}
                    error={errors.companyName ? true : false}
                    helperText={errors.companyName && `${errors.companyName}`}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="country" className="form-label">
                  {t("contact-country")}*
                </label>
                <div className="mb-3">
                  <TextField
                    value={formData.country}
                    id="country"
                    select
                    sx={{
                      "& fieldset": { border: "none" },
                      background: "#F0F3F6",
                      borderRadius: "5px"
                    }}
                    required
                    variant="outlined"
                    style={{ width: "100%" }}
                    onChange={(e) => setFormData({ ...formData, country: e.target.value })}
                    error={errors.country ? true : false}
                    helperText={errors.country && `${errors.country}`}
                  >
                    {currencies.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        <ReactCountryFlag
                          countryCode={getCountryCode(option.label)}
                          svg
                          style={{ marginRight: "8px" }}
                        />
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
            </div>
            <label htmlFor="message" className="form-label">
              {t("contact-message")}*
            </label>

            <div className="mb-3">
              <TextField
                value={formData.msg}
                fullWidth
                rows={1}
                sx={{
                  "& fieldset": { border: "none" },
                  background: "#F0F3F6",
                  borderRadius: "5px"
                }}
                variant="outlined"
                maxRows={12}
                placeholder="Type your message here...."
                onChange={(e) => setFormData({ ...formData, msg: e.target.value })}
                error={errors.msg ? true : false}
                helperText={errors.msg && `${errors.msg}`}
              />
            </div>

            <p style={{ color: "#5A7184" }}>{t("contact-note")}</p>
            <button
              type="submit"
              onClick={handleSubmit}
              className="login-form-button"
              style={{
                padding: "10px 30px",
                alignItems: "center",
                margin: "auto",
                display: "flex",
                textAlign: "center",
              }}
            >
              {t("contact-btn")}
            </button>
          </form>
        </div>

        {/* Contact Header */}
        <header class="jumbotron">
          <div
            style={{
              margin: "auto",
              display: "block",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <div class="row row-header">
              <div
                class="col-12 col-sm-6"
                style={{
                  margin: "auto",
                  display: "block",
                  textAlign: "center",
                  alignItems: "center",
                  marginTop: "-15vh",
                }}
              >
                <h1 style={{ fontSize: "6vh" }}>
                  <b>{t("contact-header-heading")}</b>
                </h1>
                <p className="my-3">{t("contact-header-desc")}</p>
              </div>
            </div>
          </div>
        </header>
      </div>

      {/* Contact footer */}
      <div className="contentFooter">
        <div className="row footerContainer">
          <div className="col-sm-4 mb-3 mb-sm-0">
            <div className="card  contact-footer-card first">
              <div className="card-body d-flex flex-column align-items-center justify-content-center">
                <div className="contact-bg">
                  <img src={emailIcon} alt="..." className="my-3" />
                </div>
                <h5 className="card-title my-3">{t("contact-footer-email-us")}</h5>
                <p className="card-text">{t("contact-footer-email-us-desc")}</p>
                <p className="contact-p">
                  <a
                    href="mailto:noreply@onboardiq.io"
                    className="contact-email-link"
                    style={{ textDecoration: "none", color: "#56367F" }}
                  >
                    noreply@onboardiq.io
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-4 mb-3 mb-sm-0">
            <div className="card bg-transparent contact-footer-card">
              <div className="card-body d-flex flex-column align-items-center justify-content-center">
                <div className="contact-bg">
                  <img src={call} alt="..." className="my-3" />
                </div>
                <h5 className="card-title my-3">{t("contact-footer-call-us")}</h5>
                <p className="card-text">{t("contact-footer-call-us-desc")}</p>
                <p className="contact-p">+1 (123) 456-7890</p>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="card bg-transparent contact-footer-card">
              <div className="card-body d-flex flex-column align-items-center justify-content-center">
                <div className="contact-bg">
                  <img src={support} alt="..." className="my-3" />
                </div>
                <h5 className="card-title my-3">{t("contact-footer-support")}</h5>
                <p className="card-text">{t("contact-footer-support-desc")}</p>
                <a href="mailto:support@onboardiq.io" className="btn btn-outline contact-p">
                  {t("contact-footer-support")}{" "}
                  <i className="fa fa-arrow-right" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
