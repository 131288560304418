import React, { useEffect, useState } from "react";
import Navbar from "../../Home/HomeSections/Section1/Navbar";
import footerImg1 from "../../../assets/img/pi-5.png";
import Section0 from "./PISections/Section0";
import Section1 from "./PISections/Section1";
import Section2 from "./PISections/Section2";
import Section3 from "./PISections/Section3";
import Section4 from "./PISections/Section4";
import Section5 from "./PISections/Section5";
import Section6 from "./PISections/Section6";
import { PI_REPORT_URL } from "../../../Auth_API";
import { UserLogin } from "../../../context/AuthContext";
import NoPI from "./PISections/NoPI";
import AppLoader from "../../Loader/AppLoader";
import axios from "axios";
import { t } from "i18next";
import FetchingLoadingOverlay from "../../FetchingLoadingOverlay/FetchingLoadingOverlay";

function PIReportMain() {
  const [loading, setLoading] = useState(true);
  const { userData, setPIReportData, piReportData } = UserLogin();
  const token = localStorage.getItem("token");
  const { predictive_index_id } = userData || {};
  const storedPiSubmissionId = localStorage.getItem("piSubmissionId");
  const submissionId = predictive_index_id || storedPiSubmissionId;

  useEffect(() => {
    console.log("submission id:", submissionId);
    console.log("token", token);

    const fetchReport = async () => {
      setLoading(true);

      // Retrieve piSubmissionId from localStorage
      try {
        const response = await axios.get(`${PI_REPORT_URL}/${submissionId}/report/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        console.log("response of pi report", response);
        const data = response.data;
        setPIReportData(data);
      } catch (error) {
        console.error("Failed to fetch report:", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchReport();
  }, [predictive_index_id, token]);

  if (loading) {
    return <FetchingLoadingOverlay />;
  }

  if (piReportData) {
    return (
      <>
        <Navbar />
        <p className="pi-title px-5 mt-3">{t("nav-pi-report-text")}</p>
        <Section0 />
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
      </>
    );
  }

  if (!piReportData) {
    return (
      <>
        <Navbar />
        <div style={{ marginBottom: "50%", marginTop: "5%" }}>
          <p className="pi-title px-5">{t("nav-pi-report-text")}</p>
          <NoPI />
        </div>
        <img src={footerImg1} alt="Footer" className="pi-top-img pifooter-img" />
      </>
    );
  }

  return null;
}

export default PIReportMain;
