import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Toolbar } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { UserLogin } from "../../context/AuthContext";
import Navbar from "../Home/HomeSections/Section1/Navbar";
import { USER_PROFILE_URL } from "../../Auth_API";
import EmptySearch from "../Loader/EmptySearch";
import searchIcon from "../../assets/img/searchIcon.svg";
import noReportImg from "../../assets/img/noReportImg.png";
import FetchingLoadingOverlay from "../FetchingLoadingOverlay/FetchingLoadingOverlay";

function Reports() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const isScreenSmall = windowWidth <= 530;
  const { userData, reportData, setReportData, setTestName, setIsLoading, isLoading } = UserLogin();
  const { token } = userData || {};
  const [searchQuery, setSearchQuery] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const searchWords = searchQuery.split(" ");
  const reportDataFiltered = reportData.filter((report) =>
    searchWords.every((word) => report.test_name.toLowerCase().includes(word.toLowerCase()))
  );

  /* Functions for Search Input Field */
  const handleSearchClick = () => {
    setIsExpanded(!isExpanded);
  };

  /* Report card responsiveness */
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /* Function to get the Reports for user */
  useEffect(() => {
    const fetchReport = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`${USER_PROFILE_URL}/user_tests_taken`, {
          method: "GET",
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setReportData(data);
          if (data && data.length > 0) {
            // setTestName(data[0].test_name);
            // setSubmissionId(data[0].id);
            // localStorage.setItem('submissionId', data[0].id);
          }
          console.log("report token data", data);
          setIsLoading(false);
        } else {
          console.error("Failed to fetch report data");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error while fetching report:", error);
        setIsLoading(false);
      }
    };

    fetchReport();
  }, []);

  const handleSetSubmissionID = (index) => {
    console.log("arrow clicked,index:", index);
    console.log("Report data,", reportData);
    setTestName(reportData[index].test_name);
    localStorage.setItem("submissionId", reportData[index].id);
    navigate("/SelectedReport");
  };

  // const submission_date = selectedAudioReport?.total_audio_duration;
  return (
    <>
      <Navbar />
      <div className="my-5" style={{ padding: "10px 70px 0 70px" }}>
        {
          isLoading &&
          <FetchingLoadingOverlay />
        }
        <Box className={`d-flex flex-column align-items-center ${isLoading && "opacity-25"}`}>
          {/* Search field */}
          <div className=" col-12 d-flex justify-content-center align-items-center">
            <div className="d-flex align-items-center gap-2 search-container col-sm-7 col-lg-5 col-12">
              <img src={searchIcon} alt="searchIcon" width={20} onClick={handleSearchClick} />
              <input
                className="search-input"
                type="text"
                placeholder="Search here ....."
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>

          {/* List of Reports */}
          <>
            {reportDataFiltered.map((report, index) => (
              <div
                key={report.id}
                className="report-card mt-5 col-12 col-md-6 col-lg-6"
                style={{
                  borderRadius: "18px",
                  overflow: "hidden",
                  fontWeight: "bold",
                  boxShadow: "0px 24px 73.9px 0px #0000001A",
                }}
              >
                {report.is_report_generated ? (
                  <div
                    className={`card-body ${isScreenSmall ? "card-small-screen" : "card-larger-screen"
                      }`}
                  >
                    <div style={{ flex: "1", fontWeight: "bold" }}>
                      {index + 1}. {report.test_name}
                    </div>
                    <div style={{ flex: "1", color: "gray", marginLeft: "10px" }}>
                      {new Date(report.submission_date).toLocaleDateString("en-GB")}
                    </div>
                    <button
                      to="/SelectedReport"
                      onClick={() => handleSetSubmissionID(index)}
                      className="navigate-report-btn fw-normal py-2"
                    >
                      <i class="bi bi-chevron-right"></i>
                    </button>
                  </div>
                ) : (
                  <div
                    className={`card-body ${isScreenSmall ? "card-small-screen" : "card-larger-screen"
                      }`}
                  >
                    <div style={{ flex: "1", fontWeight: "bold" }}>
                      {index + 1}. {report.test_name}
                    </div>
                    <div style={{ flex: "1", color: "gray", marginLeft: "10px" }}>
                      {new Date(report.submission_date).toLocaleDateString("en-GB")}
                    </div>


                    <p className="m-0">Report not generated!</p>
                  </div>
                )}
              </div>
            ))}

            {reportDataFiltered.length === 0 && (
              <div className="mt-5 d-flex flex-column align-items-center">
                <img src={noReportImg} alt="noReportImg" width={isScreenSmall ? 300 : 400} />
                <h3 className="text-center fw-bold mt-3">No Such Report Found!</h3>
              </div>
            )}
          </>
        </Box>
      </div>
      {/* {isLoading ? <EmptySearch /> : null} */}
    </>
  );
}

export default Reports;
